export const PAYMENT_METHOD = {
	cash: 1,
	visa: 2,
};
// export const PAYMENT__METHOD = {
// 	cash: 1,
// 	online: 2,
//   };
export enum PAYMENT_METHODS {
	CASH ='cash',
	VISA = 'visa',
}
export type PaymentMethodsType =
	(typeof PAYMENT_METHOD)[keyof typeof PAYMENT_METHOD];

export const BOOKING_SCHEDULE = {
	academy: "academy",
	playground: "playground",
};
export const PAYMENT_METHOD_PROVIDERS={
	PAYMOB:'paymob',
	XPAY:'xpay'
}
export type PaymentMethodProvidersType =( typeof PAYMENT_METHOD_PROVIDERS)[keyof typeof PAYMENT_METHOD_PROVIDERS]
export type BookingScheduleType =
	(typeof BOOKING_SCHEDULE)[keyof typeof BOOKING_SCHEDULE];
